body {
  background-color: #262633;
}
.content-wrapper {
  width: 80%;
  align-items: center;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 40%;
  left: 50%;
}

.content-wrapper__logo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.content-wrapper__content {
  font-family: "Gotham Pro", sans-serif;
  color: #ccd1e3;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

@media (min-width: 800px) {
  .content-wrapper {
    top: 45%;
    left: 50%;
  }
  .content-wrapper__content {
    font-size: 36px;
    display: block;
    width: 450px;
    margin: auto;
  }
}
